import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class CandidateOnlineExamSupervisorService
{
    #api = null;
    constructor() {
        this.#api = API_URL + 'admin/candidate-online-exam-supervisor';
    }

    getByOnlineExamResultId(resultId) {
        let url = `${this.#api}/${resultId}`
        return apiService.get(url)
    }
    create(data){
        let url = `${this.#api}`;
        return apiService.post(url,data)
    }
}
